export const APP_EXCLUDE_FROM_FAKEDOOR_EXPERIMENT = 'APP_EXCLUDE_FROM_FAKEDOOR_EXPERIMENT';
export const APP_INVOICE_LEVEL_FASTPAY_FAKEDOOR = 'APP_INVOICE_LEVEL_FASTPAY_FAKEDOOR';
export const APP_CUSTOMER_REVIEW_SUMMARY_PULL_FROM_DB = 'APP_CUSTOMER_REVIEW_SUMMARY_PULL_FROM_DB';
export const AUTOBOTS_CALENDAR_VIEW = 'AUTOBOTS_CALENDAR_VIEW';
export const AUTOBOTS_SENSOR_DATA_EXPORTS = 'AUTOBOTS_SENSOR_DATA_EXPORTS';
export const AUTOBOTS_STAFF_SCHEDULE = 'AUTOBOTS_STAFF_SCHEDULE';
export const AUTOBOTS_VENDORS_CREATE_WORK_ORDERS = 'AUTOBOTS_VENDORS_CREATE_WORK_ORDERS';
export const CONFIRM_CONTACT_INFO = 'CONFIRM_CONTACT_INFO';
export const CORE_MARKET = 'CORE_MARKET';
export const DIRECT_ASSIGNMENT = 'DIRECT_ASSIGNMENT';
export const DISABLE_ISSUE_TREE = 'DISABLE_ISSUE_TREE';
export const KUSTOMER_HELP = 'KUSTOMER_HELP';
export const KUSTOMER_WORKORDER_ESCALATIONS = 'KUSTOMER_WORKORDER_ESCALATIONS';
export const NOTIFICATION_TEST_LIST = 'NOTIFICATION_TEST_LIST';
export const STAFF_SELECT_HIDE_ADMIN = 'STAFF_SELECT_HIDE_ADMIN';
export const VENDOR_CUSTOMERS = 'VENDOR_CUSTOMERS';
export const VENDOR_SOURCING_PARTS_ETA = 'VENDOR_SOURCING_PARTS_ETA';
export const VORTEX_OFF_RESQ_CUSTOMER_PORTAL = 'VORTEX_OFF_RESQ_CUSTOMER_PORTAL';
export const VORTEX_SCHEDULE_PAYMENT = 'VORTEX_SCHEDULE_PAYMENT';
export const VULCAN_DISPATCH_TO_INVITED_VENDOR = 'VULCAN_DISPATCH_TO_INVITED_VENDOR';
export const VULCAN_FAST_PAY_INCENTIVE = 'VULCAN_FAST_PAY_INCENTIVE';
export const VULCAN_STORE_VENDOR_REMITTANCE_PDF = 'VULCAN_STORE_VENDOR_REMITTANCE_PDF';
export const VULCAN_VENDOR_MAINTENANCE_PLANS = 'VULCAN_VENDOR_MAINTENANCE_PLANS';
export const VULCAN_VERIFIED_VENDOR = 'VULCAN_VERIFIED_VENDOR';
export const WORK_ORDER_REVIEWS_NOTIFICATION = 'WORK_ORDER_REVIEWS_NOTIFICATION';
export const VORTEX_INVOICE_SETS_PAGE = 'VORTEX_INVOICE_SETS_PAGE';
export const VULCAN_INVOICE_SET_DISPUTE = 'VULCAN_INVOICE_SET_DISPUTE';
export const VORTEX_CREDIT_CARD_FEE = 'VORTEX_CREDIT_CARD_FEE';
export const VORTEX_OFF_PLATFORM_PROCESSING_FEE = 'VORTEX_OFF_PLATFORM_PROCESSING_FEE';
export const VULCAN_AGING_REPORT = 'VULCAN_AGING_REPORT';
export const VORTEX_REVENUE_SHARE_INVOICE_LEVEL_FEES = 'VORTEX_REVENUE_SHARE_INVOICE_LEVEL_FEES';
export const APP_TEAM_PAYQ_UPSELL_BANNERS = 'APP_TEAM_PAYQ_UPSELL_BANNERS';
export const VORTEX_INVOICES_LITE = 'VORTEX_INVOICES_LITE';
export const MONEY_JUSTIFI_INTEGRATION = 'MONEY_JUSTIFI_INTEGRATION';
export const MONEY_CREDIT_CARD_FEE_EXEMPT_CLIENT_ORG = 'MONEY_CREDIT_CARD_FEE_EXEMPT_CLIENT_ORG';
export const MONEY_LATE_FEE_ENABLED_CLIENT_ORG = 'MONEY_LATE_FEE_ENABLED_CLIENT_ORG';
export const MONEY_LATE_FEE_ENABLED_VENDOR_ORG = 'MONEY_LATE_FEE_ENABLED_VENDOR_ORG';
export const PAYQ_ENABLED_VENDOR_ORG = 'PAYQ_ENABLED_VENDOR_ORG';
export const APP_TEAM_VENDOR_RECOMMENDED_WORK_ORDERS = 'APP_TEAM_VENDOR_RECOMMENDED_WORK_ORDERS';
export const MONEY_PAYCHEQ = 'MONEY_PAYCHEQ';
export const APP_COST_MANAGEMENT_EXPECTED_SERVICE_TIMELINE =
    'APP_COST_MANAGEMENT_EXPECTED_SERVICE_TIMELINE';
export const APP_TEAM_SAVINGS_OPPORTUNITY_CARD = 'APP_TEAM_SAVINGS_OPPORTUNITY_CARD';
export const APP_TEAM_CYOV_WARRANTY_CHANGES = 'APP_TEAM_CYOV_WARRANTY_CHANGES';
export const APP_TEAM_SHOW_CLIENT_REMITTANCE_PDF = 'APP_TEAM_SHOW_CLIENT_REMITTANCE_PDF';
export const APP_TEAM_ENABLE_QUICKBOOKS_INTEGRATION = 'APP_TEAM_ENABLE_QUICKBOOKS_INTEGRATION';
export const SENSORS_DISABLED_ALERTS = 'SENSORS_DISABLED_ALERTS';
export const APP_TEAM_PAYQ_ONBOARDING_IMPROVEMENTS = 'APP_TEAM_PAYQ_ONBOARDING_IMPROVEMENTS';
